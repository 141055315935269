.custom-tooltip {
  position: fixed;
  z-index: 200;
  opacity: 0;
  padding: 6px 13px;
  line-height: 18px;
  color: #f7f7f7;
  border-radius: 3px;
  font-family: roboto;
  font-size: 13px;
  background: #333333;
  text-align: center;
  overflow-wrap: break-word;
}

.tooltip-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tooltip-container.top-tooltip {
  left: calc(100% / 2);
  bottom: calc(100%);
}

.tooltip-container.bottom-tooltip {
  left: calc(100% / 2);
}

.right-tooltip {
  left: calc(100% + 4px);
}

.bottom-tooltip {
  top: calc(100% + 4px);
}

.top-tooltip {
  bottom: calc(100% + 4px);
}

.right-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #333333;
  opacity: .8;
  position: absolute;
  left: -8px;
  top: calc(50% - 8px);
}

.bottom-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #333333;
  opacity: .8;
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);
}

.top-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #333333;
  opacity: .8;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 8px);
}

@font-face {
  font-family: roboto;
  src: url('../../assets/fonts/roboto/Roboto-Light-webfont.eot');
  src: url('../../assets/fonts/roboto/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/roboto/Roboto-Light-webfont.woff') format('woff'),
  url('../../assets/fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'),
  url('../../assets/fonts/roboto/Roboto-Light-webfont.svg#icon') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: roboto;
  src: url('../../assets/fonts/roboto/Roboto-Regular-webfont.eot');
  src: url('../../assets/fonts/roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/roboto/Roboto-Regular-webfont.woff') format('woff'),
  url('../../assets/fonts/roboto/Roboto-Regular-webfont.ttf') format('truetype'),
  url('../../assets/fonts/roboto/Roboto-Regular-webfont.svg#icon') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: roboto;
  src: url('../../assets/fonts/roboto/Roboto-Medium-webfont.eot');
  src: url('../../assets/fonts/roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/roboto/Roboto-Medium-webfont.woff') format('woff'),
  url('../../assets/fonts/roboto/Roboto-Medium-webfont.ttf') format('truetype'),
  url('../../assets/fonts/roboto/Roboto-Medium-webfont.svg#icon') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: roboto;
  src: url('../../assets/fonts/roboto/Roboto-Bold-webfont.eot');
  src: url('../../assets/fonts/roboto/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/roboto/Roboto-Bold-webfont.woff') format('woff'),
  url('../../assets/fonts/roboto/Roboto-Bold-webfont.ttf') format('truetype'),
  url('../../assets/fonts/roboto/Roboto-Bold-webfont.svg#icon') format('svg');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: roboto;
  background: #dadada;
  overflow-x: hidden;
}

.page-without-left-tree {
  padding: 30px 0 0 22px;
}

.ag-theme-bootstrap .ag-header-viewport .ag-header-group-cell-label span {
  float: none;
}

color-picker>.color-picker,
bs-datepicker-container[role='dialog']{
  z-index: 15;
}

input::-ms-clear, input::-ms-reveal {
  display: none !important;
}

body .bs-datepicker-container .bs-datepicker-head button.current {
  background-color: #449d44;
}
