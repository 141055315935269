@import url('assets/fonts/fontello/css/fontello.css');
@import url('assets/css/common.scss');
@import url('assets/css/font-awesome.min.css');

$icons-path: '~ag-grid/src/styles/icons/';
$secondary-font-weight: 700;
$border-color: #dddddd;
$cell-horizontal-border: 1px solid #dddddd;
$focused-cell-border-color: transparent;

@import '~ag-grid/src/styles/ag-grid.scss';
@import '~ag-grid/src/styles/ag-theme-bootstrap.scss';
//HIER
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

bs-datepicker-container { z-index: 9999 !important; }

.body-view-content {
    margin-left: 263px;
    padding-left: 15px;
}

@media (max-width: 1024px) {
  .body-view-content {
    margin-left: 0!important;
  }
}

:root {
  --search-widget-border: #8bc34a;
  --search-widget-label: #8bc34a;
  --search-widget-label-dark-green: #008366;
  --search-widget-label-orange: #f39224;
  --search-widget-button: #5cb85c;
  --main-static: #008366;
  --main-static-orange: #f39224;
  --form-button: #5cb85c;
  --form-button-action: #449d44;
}

div {
  $search-widget-border: var(--search-widget-border);
  $search-widget-label: var(--search-widget-label);
  $search-widget-label-dark-green: var(--search-widget-label-dark-green);
  $search-widget-label-orange: var(--search-widget-label-orange);
  $search-widget-button: var(--search-widget-button);
  $main-static: var(--main-static);
  $main-static-orange: var(--main-static-orange);
  $form-button: var(--form-button);
  $form-button-action: var(--form-button-action);
}