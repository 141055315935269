@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?85903690');
  src: url('../font/fontello.eot?85903690#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?85903690') format('woff2'),
       url('../font/fontello.woff?85903690') format('woff'),
       url('../font/fontello.ttf?85903690') format('truetype'),
       url('../font/fontello.svg?85903690#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?85903690#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-fc-diary:before { content: '\e800'; } /* '' */
.icon-fc-work-planning:before { content: '\e801'; } /* '' */
.icon-fc-field-accessibility:before { content: '\e802'; } /* '' */
.icon-fc-poultry:before { content: '\e803'; } /* '' */
.icon-fc-weather-forecast:before { content: '\e804'; } /* '' */
.icon-fc-animals-services:before { content: '\e805'; } /* '' */
.icon-fc-disease-models:before { content: '\e806'; } /* '' */
.icon-fc-soil-moisture:before { content: '\e807'; } /* '' */
.icon-fc-plant-protection:before { content: '\e808'; } /* '' */
.icon-fc-cropview:before { content: '\e809'; } /* '' */
.icon-fc-tillage-ability:before { content: '\e80a'; } /* '' */
.icon-fc-weather-forecast2:before { content: '\e80b'; } /* '' */
.icon-fc-hay-sillage:before { content: '\e80c'; } /* '' */
.icon-fc-irrigation-scheduling:before { content: '\e80d'; } /* '' */
.icon-fc-tropical-sowing:before { content: '\e80e'; } /* '' */
.icon-fc-fertilize:before { content: '\e80f'; } /* '' */
.icon-fc-sowing:before { content: '\e810'; } /* '' */
.icon-fc-farm:before { content: '\e811'; } /* '' */
.icon-fc-irrigation:before { content: '\e812'; } /* '' */
.icon-fc-water-level:before { content: '\e813'; } /* '' */
.icon-fc-yield-prediction:before { content: '\e814'; } /* '' */
.icon-webshop:before { content: '\e815'; } /* '' */
.icon-fc-satellites:before { content: '\e816'; } /* '' */
.icon-fc-satellite-icon-gray:before { content: '\e817'; } /* '' */
.icon-fc-weatherlink:before { content: '\e818'; } /* '' */
.icon-fc-sigma-leaf:before { content: '\e819'; } /* '' */
.icon-fc-degree-days:before { content: '\e81a'; } /* '' */
.icon-fc-chilling-units:before { content: '\e81b'; } /* '' */
.icon-fc-ultrasonic-distance-settings:before { content: '\e81c'; } /* '' */
.icon-fc-satellite:before { content: '\e81d'; } /* '' */
.icon-fc-precipitation-radar:before { content: '\e81e'; } /* '' */
.icon-fc-asparagus:before { content: '\e81f'; } /* '' */
.icon-fc-hydroponics:before { content: '\e820'; } /* '' */
.icon-fc-dualex:before { content: '\e821'; } /* '' */
.icon-fc-samples-cropzone:before { content: '\e822'; } /* '' */
.icon-fc-soilguard:before { content: '\e823'; } /* '' */
.icon-fc-rain-corrector:before { content: '\e824'; } /* '' */
.icon-fc-temperature-accumulation:before { content: '\e825'; } /* '' */
.icon-fc-vws-icon:before { content: '\e826'; } /* '' */
.icon-fc-object-tracker:before { content: '\e827'; } /* '' */
.icon-fc-active-tracker:before { content: '\e828'; } /* '' */
.icon-fc-frost-monitoring:before { content: '\e829'; } /* '' */
.icon-fc-history-climate-black:before { content: '\e82b'; } /* '' */
.icon-fc-rain-sum:before { content: '\e838'; } /* '' */
.icon-fc-yp-graph:before { content: '\e856'; } /* '' */
.icon-fc-previous-yield:before { content: '\e857'; } /* '' */
